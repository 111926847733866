import React from "react";

interface Pages {
  path: string;
  isPublic: boolean;
  component: React.LazyExoticComponent<React.FC>;
}

let pages: Pages[] = [
  {
    path: "/kelola/dashboard",
    isPublic: false,
    component: React.lazy(() => import("../pages/dashboard")),
  },
  {
    path: "/kelola/carousel",
    isPublic: false,
    component: React.lazy(() => import("../pages/carousel")),
  },
  {
    path: "/kelola/user",
    isPublic: false,
    component: React.lazy(() => import("../pages/user")),
  },
  {
    path: "/kelola/layanan/umk",
    isPublic: false,
    component: React.lazy(() => import("../pages/services/umk")),
  },
  {
    path: "/kelola/detail/umk/:id",
    isPublic: false,
    component: React.lazy(() => import("../pages/detail/umk")),
  },
  {
    path: "/kelola/layanan/nonumk",
    isPublic: false,
    component: React.lazy(() => import("../pages/services/nonumk")),
  },
  {
    path: "/kelola/detail/nonumk/:id",
    isPublic: false,
    component: React.lazy(() => import("../pages/detail/nonumk")),
  },
  {
    path: "/kelola/layanan/yayasan",
    isPublic: false,
    component: React.lazy(() => import("../pages/services/yayasan")),
  },
  {
    path: "/kelola/detail/yayasan/:id",
    isPublic: false,
    component: React.lazy(() => import("../pages/detail/yayasan")),
  },
  {
    path: "/kelola/layanan/perjanjian",
    isPublic: false,
    component: React.lazy(() => import("../pages/services/perjanjian")),
  },
  {
    path: "/kelola/detail/perjanjian/:id",
    isPublic: false,
    component: React.lazy(() => import("../pages/detail/perjanjian")),
  },
  {
    path: "/kelola/layanan/hukum",
    isPublic: false,
    component: React.lazy(() => import("../pages/services/hukum")),
  },
  {
    path: "/kelola/detail/hukum/:id",
    isPublic: false,
    component: React.lazy(() => import("../pages/detail/hukum")),
  },
  {
    path: "/kelola/layanan/perizinan",
    isPublic: false,
    component: React.lazy(() => import("../pages/services/perizinan")),
  },
  {
    path: "/kelola/detail/perizinan/:id",
    isPublic: false,
    component: React.lazy(() => import("../pages/detail/perizinan")),
  },
  {
    path: "/kelola/layanan/merek",
    isPublic: false,
    component: React.lazy(() => import("../pages/services/merek")),
  },
  {
    path: "/kelola/detail/merek/:id",
    isPublic: false,
    component: React.lazy(() => import("../pages/detail/merek")),
  },
  {
    path: "/kelola/layanan/pajak",
    isPublic: false,
    component: React.lazy(() => import("../pages/services/pajak")),
  },
  {
    path: "/kelola/detail/pajak/:id",
    isPublic: false,
    component: React.lazy(() => import("../pages/detail/pajak")),
  },
  {
    path: "/web/promo",
    isPublic: false,
    component: React.lazy(() => import("../pages/web/promo")),
  },
  {
    path: "/web/carousel",
    isPublic: false,
    component: React.lazy(() => import("../pages/web/carousel")),
  },
  {
    path: "/web/layanan",
    isPublic: false,
    component: React.lazy(() => import("../pages/web/layanan")),
  },
  {
    path: "/web/testimoni",
    isPublic: false,
    component: React.lazy(() => import("../pages/web/testimoni")),
  },
  {
    path: "/web/faq",
    isPublic: false,
    component: React.lazy(() => import("../pages/web/faq")),
  },
  {
    path: "/web/portofolio",
    isPublic: false,
    component: React.lazy(() => import("../pages/web/portofolio")),
  },
  {
    path: "/web/supported",
    isPublic: false,
    component: React.lazy(() => import("../pages/web/supported")),
  },
  {
    path: "/web/input",
    isPublic: false,
    component: React.lazy(() => import("../pages/web/input")),
  },
  {
    path: "/web/contact",
    isPublic: false,
    component: React.lazy(() => import("../pages/web/contact")),
  },
  {
    path: "/web/artikel",
    isPublic: false,
    component: React.lazy(() => import("../pages/web/artikel")),
  },
  {
    path: "/",
    isPublic: true,
    component: React.lazy(() => import("../pages/login")),
  },
  {
    path: "/login",
    isPublic: true,
    component: React.lazy(() => import("../pages/login")),
  },
];

export default pages;
